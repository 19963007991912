import React from 'react';
import { BrowserRouter as Router} from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './assets/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import './assets/style.css';
import './assets/css/colors.css';
import './assets/css/responsive.css';

function App() {
  return (
    <Router>
      <div id="pageWrapper">
        <div className="phStickyWrap">
          <div className="headerFixer fixed-top">
            <Header/>
          </div>
        </div>
        <main>
          <Home/>
        </main>
        <Footer/>
      </div>
    </Router>
  );
}

export default App;
