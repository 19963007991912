import React,{ useState } from 'react';
import {IbsSlider,Services,Photogallery,Testimonial,Sponsorslogo} from '../components/IbsSlider';
import ServiceCounter from '../components/ServiceCounter';
import {PricingPlan,Workprocess,Contact} from '../components/Work';

function About(){
	return(
		<>
			<article id="about" className="aboutBlock pt-13 pb-7 pt-md-15 pb-md-13 pt-lg-22 pb-lg-15 pt-xl-22 pb-xl-23">
				<div className="container">
					<div className="row">
						<div className="col-12 col-lg-6 order-lg-2">
							<div className="imgHolder imgHolderv1 position-relative mx-auto mb-13 mb-sm-15 mb-lg-0 mx-lg-0">
								<img src={require("../assets/images/img02.jpg")} className="w-100 img-fluid" alt="description" />
								<div className="descrTag descrTagv1 position-absolute d-inline-flex align-items-center bg-white fontAlter">
									<i className="lnr lnr-flag icn mr-2"><span className="sr-only">icon</span></i>
									<strong className="d-block fwMedium">25 years of <br />quality service</strong>
								</div>
								<span className="bgPattern bgPatternv1 position-absolute">
									<img src={require("../assets/images/bgPattern01.png")} className="img-fluid" alt="pattern" />
								</span>
							</div>
						</div>
						<div className="col-12 col-lg-6 d-flex align-items-lg-center order-lg-1">
							<div className="align pr-lg-10 pr-xl-15">
								<header className="headingHead mb-5">
									<h2>
										<strong className="d-block text-uppercase hTitle fwMedium mb-4">Who We Are</strong>
										<span className="d-block">Professional Auto washing and detailing services</span>
									</h2>
								</header>
								<p>Over the past 6 years we cleaned over 34,000 cars, saved over 8.9 million liters of water from being used in car washing and employed 50 youth. Our legacy is in the youth that gained work and life skills, grew their confidence and have moved on to bigger.</p>
							</div>
						</div>
					</div>
				</div>
			</article>
		</>
	)
}
function Experienceblock() {
	const [activePin, setActivePin] = useState(null);

  	const toggleActive = (index) => {
    	setActivePin(index === activePin ? null : index);
  	};
  	return (
  		<>
	    <article className="experienceBlock position-relative py-8 pt-md-10 pb-md-60 pt-lg-14 pb-lg-56 pt-xl-21 pb-xl-23 mb-md-12 mb-xlwd-24">
	      <div className="container">
	        <div className="row">
	          <div className="col-12 col-md-9 offset-md-3 col-lg-6 offset-lg-6">
	            <div className="extraWrap pl-lg-13">
	              <header className="fontAlter mb-6">
	                <h2 className="text-white mb-6">25 Years of Experience in Carwash Industry</h2>
	                <p>Carwash made its debut in America in 1998, bringing with us a 40 year legacy in the convenience-store and car wash.</p>
	              </header>
	              <ul className="list-unstyled servicesList">
	                <li>Platinum exterior wash</li>
	                <li>Door panels / seats wiped</li>
	                <li>Hard surfaces cleaned &amp; disinfected</li>
	                <li>Rubber mats washed</li>
	              </ul>
	              <button type="button" className="btn btnThemeAlt position-relative border-0 p-0 mt-5 mt-md-8 btn btnMinMedium" data-hover="Explore More">
	                <span className="d-block btnText">Explore More</span>
	              </button>
	            </div>
	          </div>
	        </div>
	        <div className="imgHolderCap position-absolute d-none d-md-block inaccessible">
	          <img src={require("../assets/images/img06.png")} className="img-fluid" alt="description" />
	          <div key={1} className={`imhcPinWrap imhcPinWrap1 position-absolute ${activePin === 1 ? 'active' : ''}`}>
	            <a className="imhcPinOpener fontAlter text-white font-weight-light d-flex align-items-center justify-content-center rounded-circle position-relative" onMouseEnter={() => toggleActive(1)}>+</a>
	            <div className="imhcCaption bg-white rounded shadow position-absolute text-center px-1 py-3 transition transitOpVis">
	              <strong className="d-block h h3 fwMedium mb-0">Complete Car Wash Services </strong>
	            </div>
	          </div>
	          <div key={2} className={`imhcPinWrap imhcPinWrap2 position-absolute ${activePin === 2 ? 'active' : ''}`}>
	            <a className="imhcPinOpener fontAlter text-white font-weight-light d-flex align-items-center justify-content-center rounded-circle position-relative" onMouseEnter={() => toggleActive(2)}>+</a>
	            <div className="imhcCaption bg-white rounded shadow position-absolute text-center px-1 py-3 transition transitOpVis">
	              <strong className="d-block h h3 fwMedium mb-0">Complete Car Wash Services </strong>
	            </div>
	          </div>
	          <div key={3} className={`imhcPinWrap imhcPinWrap3 position-absolute ${activePin === 3 ? 'active' : ''}`}>
	            <a className="imhcPinOpener fontAlter text-white font-weight-light d-flex align-items-center justify-content-center rounded-circle position-relative" onMouseEnter={() => toggleActive(3)}>+</a>
	            <div className="imhcCaption bg-white rounded shadow position-absolute text-center px-1 py-3 transition transitOpVis">
	              <strong className="d-block h h3 fwMedium mb-0">Complete Car Wash Services </strong>
	            </div>
	          </div>
	        </div>
	      </div>
	      <span className="expWatermark position-absolute overflow-hidden w-100 h-100 inaccessible">
	        <i className="icomoon-3345656 icn position-absolute"><span className="sr-only">icon</span></i>
	      </span>
	    </article>
	    </>
  	);
}

function Home(){
    const [selectedService, setSelectedService] = useState('');
    const handleServiceSelect = (service) => {
        setSelectedService(service);
    };
    return(
    <>
        <IbsSlider />
        <About />
        <Services />
        <ServiceCounter/>
        <Experienceblock/>
        <PricingPlan onSelectService={handleServiceSelect}/>
        <Workprocess/>
        <Photogallery/>
        <Testimonial/>
        <Sponsorslogo/>
        <Contact selectedService={selectedService}/>
    </>
    )
}

export default Home;