import React, { useEffect, useState, useRef } from 'react';

const LoopCounter = ({ start, end, duration }) => {
  const [count, setCount] = useState(start);

  useEffect(() => {
    const startTime = new Date().getTime();
    const endTime = startTime + duration;
    const increment = (end - start) / duration;

    const timer = setInterval(() => {
      const now = new Date().getTime();
      const elapsed = now - startTime;
      
      if (now < endTime) {
        const newCount = start + increment * elapsed;
        setCount(newCount);
      } else {
        setCount(end);
        clearInterval(timer);
      }
    }, 10);

    return () => clearInterval(timer);
  }, [start, end, duration]);

  return <span>{Math.floor(count)}</span>;
};

function ServiceCounter(){
	const counterRef = useRef(null);
  	const [isVisible, setIsVisible] = useState(false);

  	useEffect(() => {
	    const options = {
	      root: null,
	      rootMargin: '0px',
	      threshold: 0.5,
	    };

	    const observer = new IntersectionObserver((entries) => {
	      entries.forEach((entry) => {
	        if (entry.isIntersecting) {
	          setIsVisible(true);
	          observer.unobserve(entry.target);
	        }
	      });
	    }, options);

	    if (counterRef.current) {
	      observer.observe(counterRef.current);
	    }

	    return () => {
	      if (counterRef.current) {
	        observer.unobserve(counterRef.current);
	      }
	    };
  	}, []);
	return (
		<>
			<aside className="counterAsideBlock position-relative pt-6 pb-1 pt-md-9 pb-md-3 pt-lg-15 pb-lg-10 pt-xl-20 pb-xl-14" style={{ backgroundImage: `url(${require("../assets/images/bgPattern03.png")})` }} ref={counterRef}>
				<div className="container">
					<ul className="list-unstyled cabCounterList d-flex flex-wrap justify-content-around mb-0 mx-auto mx-md-0">
						<li>
							<h3 className="cclColumnWrap fwSemi text-uppercase d-flex mb-0 align-items-center">
								<span className="font-weight-normal flex-shrink-0 textCount mr-1 mr-sm-3 dataCount">
									{isVisible && <LoopCounter start={0} end={25} duration={1000} />}
								</span>
								<span className="text-white subtitle">Years of <br/>Experience</span>
							</h3>
						</li>
						<li>
							<h3 className="cclColumnWrap fwSemi text-uppercase d-flex mb-0 align-items-center">
								<span className="font-weight-normal flex-shrink-0 textCount mr-1 mr-sm-3"><span className="dataCount">
								{isVisible && <LoopCounter start={0} end={38} duration={1000} />}
								</span>K</span>
								<span className="text-white subtitle">Total Cars <br/>Washed</span>
							</h3>
						</li>
						<li>
							<h3 className="cclColumnWrap fwSemi text-uppercase d-flex mb-0 align-items-center">
								<span className="font-weight-normal flex-shrink-0 textCount mr-1 mr-sm-3 dataCount">
								{isVisible && <LoopCounter start={0} end={17} duration={1000} />}
								</span>
								<span className="text-white subtitle">Awards &amp; <br/>Recongnitions</span>
							</h3>
						</li>
						<li>
							<h3 className="cclColumnWrap fwSemi text-uppercase d-flex mb-0 align-items-center">
								<span className="font-weight-normal flex-shrink-0 textCount mr-1 mr-sm-3"><span className="dataCount">
								{isVisible && <LoopCounter start={0} end={14} duration={1000} />}
								</span>K</span>
								<span className="text-white subtitle">Trusted <br/>Clients</span>
							</h3>
						</li>
					</ul>
				</div>
			</aside>
		</>
	)
}
export default ServiceCounter;