import React,{useEffect,useRef, useState } from 'react';
import { Link as ScrollLink } from 'react-scroll';

function PricingPlan({ onSelectService }){
	const changeselect = (type) => {
		onSelectService(type);
    };
	return (
		<>
			<section className="plansBlock py-7 py-md-10 pt-xl-18 pb-xl-16" id="package">
				<div className="container">
					<div className="row">
						<div className="col-12 col-md-10 offset-md-1 col-xl-6 offset-xl-3">
							<header className="headingHead fzMedium text-center mb-8 mb-md-12">
								<h2>
									<strong className="d-block text-uppercase hTitle fwMedium mb-4">Pricing Plans</strong>
									<span className="d-block fwSemi">Choose your Package</span>
								</h2>
								<p>Unlimited Washes is for you! Wash whenever you want and enjoy the ease of auto monthly billing and you can cancel any time.</p>
							</header>
						</div>
					</div>
					<div className="row">
						<div className="col-12 col-md-6 col-xl-3 d-flex">
							<article className="w-100 mx-auto mb-6 position-relative bg-white shadow rounded text-center priceColumn pt-9 pb-30 px-6">
								<h3 className="fwMedium mb-6">Diamond Service</h3>
								<div className="row">
									<div className="col-6 price"><div className="package-bg">Sedan</div>220<sub className="subAfter">AED</sub></div>
									<div className="col-6 price"><div className="package-bg">SUV</div>250<sub className="subAfter">AED</sub></div>
								</div>
								<hr className="my-6"/>
								<ul className="list-unstyled pcFeaturesList text-left mb-0">
									<li>Interior and exterior washing</li>
									<li>Polishing the rims with Special Materials</li>
									<li>Tire Polishing</li>
									<li>Removing Interior Stains</li>
									<li>Perfuming the Car</li>
									<li>Polishing the Car With Manual Waxes</li>
									<li>Polishing the Engine with Special Material</li>
								</ul>
								<ScrollLink
			                          to="contact"
			                          spy={true}
			                          smooth={true}
			                          offset={-70} 
			                          duration={1000}
			                          className="btn btnThemeAlt position-absolute border-0 p-0 mx-auto"
			                          data-hover="Select"
			                          onClick={() => changeselect("Diamond Service")}
			                          style={{ cursor: 'pointer' }}
			                        >
			                        <span className="d-block btnText">Choose Plan</span>
		                        </ScrollLink>
							</article>
						</div>
						<div className="col-12 col-md-6 col-xl-3 d-flex">
							<article className="w-100 mx-auto mb-6 position-relative bg-white shadow rounded text-center priceColumn pt-9 pb-30 px-6">
								<h3 className="fwMedium mb-6">Golden Service</h3>
								<div className="row">
									<div className="col-6 price"><div className="package-bg">Sedan</div>140<sub className="subAfter">AED</sub></div>
									<div className="col-6 price"><div className="package-bg">SUV</div>160<sub className="subAfter">AED</sub></div>
								</div>
								<hr className="my-6"/>
								<ul className="list-unstyled pcFeaturesList text-left mb-0">
									<li>Interior and exterior washing</li>
									<li>Polishing the rims with Special Materials</li>
									<li>Tire Polishing</li>
									<li>Perfuming the Car</li>
									<li>Covering Drexion and Chairs</li>
									<li>Polishing the Car With Manual Waxes</li>
								</ul>
								<ScrollLink
			                          to="contact"
			                          spy={true}
			                          smooth={true}
			                          offset={-70} 
			                          duration={1000}
			                          className="btn btnThemeAlt position-absolute border-0 p-0 mx-auto"
			                          data-hover="Select"
			                          onClick={() => changeselect("Golden Service")}
			                          style={{ cursor: 'pointer' }}
			                        >
			                        <span className="d-block btnText">Choose Plan</span>
		                        </ScrollLink>
							</article>
						</div>
						<div className="col-12 col-md-6 col-xl-3 d-flex">
							<article className="w-100 mx-auto mb-6 position-relative bg-white shadow rounded text-center priceColumn pt-9 pb-30 px-6">
								<h3 className="fwMedium mb-6">Silver Service</h3>
								<div className="row">
									<div className="col-6 price"><div className="package-bg">Sedan</div>80<sub className="subAfter">AED</sub></div>
									<div className="col-6 price"><div className="package-bg">SUV</div>100<sub className="subAfter">AED</sub></div>
								</div>
								<hr className="my-6"/>
								<ul className="list-unstyled pcFeaturesList text-left mb-0">
									<li>Interior and exterior washing</li>
									<li>Tire Polishing</li>
									<li>Perfuming the Car</li>
									<li>Covering Drexion and Chairs</li>
									<li>Polishing the rims with Special Materials</li>
								</ul>
								<ScrollLink
			                          to="contact"
			                          spy={true}
			                          smooth={true}
			                          offset={-70} 
			                          duration={1000}
			                          className="btn btnThemeAlt position-absolute border-0 p-0 mx-auto"
			                          data-hover="Select"
			                          onClick={() => changeselect("Silver Service")}
			                          style={{ cursor: 'pointer' }}
			                        >
			                        <span className="d-block btnText">Choose Plan</span>
		                        </ScrollLink> 
							</article>
						</div>
						<div className="col-12 col-md-6 col-xl-3 d-flex">
							<article className="w-100 mx-auto mb-3 mb-md-6 position-relative bg-white shadow rounded text-center priceColumn pt-9 pb-30 px-6">
								<h3 className="fwMedium mb-6">Normal Service</h3>
								<div className="row">
									<div className="col-6 price"><div className="package-bg">Sedan</div>35<sub className="subAfter">AED</sub></div>
									<div className="col-6 price"><div className="package-bg">SUV</div>45<sub className="subAfter">AED</sub></div>
								</div>
								<hr className="my-6"/>
								<ul className="list-unstyled pcFeaturesList text-left mb-0">
									<li>Interior and exterior washing</li>
									<li>Tire Polishing</li>
									<li>Perfuming the Car</li>
								</ul>
								<ScrollLink
			                          to="contact"
			                          spy={true}
			                          smooth={true}
			                          offset={-70} 
			                          duration={1000}
			                          className="btn btnThemeAlt position-absolute border-0 p-0 mx-auto"
			                          data-hover="Select"
			                          onClick={() => changeselect("Normal Service")}
			                          style={{ cursor: 'pointer' }}
			                        >
			                        <span className="d-block btnText">Choose Plan</span>
		                        </ScrollLink>
							</article>
						</div>
					</div>
				</div>
			</section>
			<aside className="callAsideBlock">
				<div className="container">
					<div className="cabHolder position-relative rounded shadow fontAlter mx-xlwd-n10 px-5 px-sm-10 py-9 py-lg-13 overflow-hidden">
						<div className="row align-items-center">
							<div className="col-12 col-lg-8 position-static">
								<div className="extraWrap pl-sm-15 pl-lg-21">
									<i className="icomoon-information1 icn d-none d-sm-block"><span className="sr-only">icon</span></i>
									<h3 className="text-white mb-4 fwMedium">Do you need help with your vehicle?</h3>
									<p className="mb-lg-0">Send us a message, or phone <a href="tel:971506301880">+971 50 630 1880</a> between 09:00 and 18:00 Mon -Sat.</p>
								</div>
							</div>
							<div className="col-12 col-lg-4">
								<div className="extraWrap pl-sm-15 pl-lg-0 text-lg-right">
									<ScrollLink
			                          to="contact"
			                          spy={true}
			                          smooth={true}
			                          offset={-70} 
			                          duration={500}
			                          className="btn btnThemeWhite position-relative border-0 p-0 btnMinXLarge"
			                          data-hover="Request a Call Back"
			                          style={{ cursor: 'pointer' }}
			                        >
			                          <span className="d-block btnText">Request a Call Back</span>
			                        </ScrollLink>
								</div>
							</div>
						</div>
						<span className="bgPattern bgPatternv2 inaccessible position-absolute">
							<img src={require("../assets/images/bgPattern04.png")} className="img-fluid" alt="description"/>
						</span>
						<span className="bgPattern bgPatternv3 inaccessible position-absolute">
							<img src={require("../assets/images/bgPattern05.png")} className="img-fluid" alt="description"/>
						</span>
					</div>
				</div>
			</aside>
		</>
	)
}
function Workprocess(){
	return (
		<>
			<section className="processBlock bg-light2 text-center mt-n21 pt-30 pt-md-35 pt-lg-38 pt-xl-43 pb-8 pb-md-15 pb-xl-22">
				<div className="container">
					<div className="row">
						<div className="col-12 col-md-10 offset-md-1 col-xl-6 offset-xl-3">
							<header className="headingHead fzMedium mb-12">
								<h2>
									<strong className="d-block text-uppercase hTitle fwMedium mb-4">How It Works</strong>
									<span className="d-block fwSemi">Our Work Process</span>
								</h2>
								<p>Book online. We’ll provide you with a trusted, excellent services most accurate and fair-price service Estimate.</p>
							</header>
						</div>
					</div>
					<div className="row pcColumnsWrap mx-n15 mx-md-n3 mx-xl-n16">
						<div className="col-12 col-md-4 col px-15 px-md-3 px-xl-16">
							<article className="processColumn mb-8 position-relative">
								<span className="pcCountSpan position-relative mx-auto rounded-circle d-flex align-items-center justify-content-center font-weight-light mb-5"></span>
								<h3 className="fwMedium h3Medium mb-4">Make an Appointment</h3>
								<p>Book online &amp; leave your car with us. We’ll provide you with a trusted, excellent services.</p>
								<div className="imgHolder d-none d-md-block position-absolute mt-5">
									<img src={require("../assets/images/arrow1.png")} alt="description" className="img-fluid"/>
								</div>
							</article>
						</div>
						<div className="col-12 col-md-4 col px-15 px-md-3 px-xl-16">
							<article className="processColumn mb-8 position-relative">
								<span className="pcCountSpan position-relative mx-auto rounded-circle d-flex align-items-center justify-content-center font-weight-light mb-5"></span>
								<h3 className="fwMedium h3Medium mb-4">Get Amzing services</h3>
								<p>Every cleaner is friendly and reliable. They’ve been background checked & rated 5-stars</p>
								<div className="imgHolder d-none d-md-block position-absolute mt-5">
									<img src={require("../assets/images/arrow2.png")} alt="description" className="img-fluid"/>
								</div>
							</article>
						</div>
						<div className="col-12 col-md-4 col px-15 px-md-3 px-xl-16">
							<article className="processColumn mb-8 position-relative">
								<span className="pcCountSpan position-relative mx-auto rounded-circle d-flex align-items-center justify-content-center font-weight-light mb-5"></span>
								<h3 className="fwMedium h3Medium mb-4">Pay Online &amp; Get your car</h3>
								<p>Pay online. We’ll provide you with a trusted, excellent services with door delivery option.</p>
							</article>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}
function Contact({ selectedService }){
	const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const selectRef = useRef(null);

    const [formData, setFormData] = useState({
        service: '',
        phone: '',
        name: '',
    });

    useEffect(() => {
    	setErrorMessage('');
        setSuccessMessage('');
    	if (selectRef.current) {
            selectRef.current.value = selectedService;
        }
        setFormData(prevState => ({
            ...prevState,
            service: selectedService
        }));
    }, [selectedService]);

    const [errors, setErrors] = useState({
        service: '',
        phone: '',
        name: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };


	const handleSubmit = async (event) => {
	    event.preventDefault();
	    const newErrors = {};
        if (formData.service.trim() === '') {
            newErrors.service = 'Service is required.';
        }
        if (formData.name.trim() === '') {
            newErrors.name = 'Name is required.';
        } else if (!/^[a-zA-Z\s]*$/.test(formData.name)) {
	      	newErrors.name = 'Name can only contain alphabets and spaces';
	    }
        if (formData.phone.trim() === '') {
            newErrors.phone = 'Phone is required.';
        } else if (!/^\d+$/.test(formData.phone)) {
	      	newErrors.phone = 'Phone number can only contain digits';
	    }
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }
          const urlEncodedData = new URLSearchParams(formData).toString();

        console.log(formData)
	    try {
	      const response = await fetch('api/sendmail.php', {
	        method: 'POST',
	        headers: {
	          'Content-Type': 'application/x-www-form-urlencoded',
	        },
	        body: urlEncodedData,
	      });
	      if (response.ok) {
	        const data = await response.json();
	        data.success ? setSuccessMessage(data.message) : setErrorMessage(data.message);
	        if(data.success){
                setFormData({
                	service: '',
			        phone: '',
			        name: '',
                });
                selectRef.current.value =  '';
            }
            setErrors({
                service: '',
		        phone: '',
		        name: '',
            });
	      } else {
	        const data1 = await response.json();
	        data1.success ? setSuccessMessage(data1.message) : setErrorMessage(data1.message);
	      }
	    } catch (error) {
	      	setErrorMessage('Failed to submit message. Please try again later.');
            setSuccessMessage('');
	    }
	};
	return (
		<>
			<aside className="requestCallAside position-relative pt-7 pt-md-9 pt-xl-16" id="contact">
				<div className="container">
					<div className="row">
						<div className="col-12 col-md-6">
							<header className="header mb-7">
								<h2 className="text-white mb-4 fwSemi">Request a Call back</h2>
								<p>We inspire clients to make their most challenging business decisions with confidence. Send us a message or Call Us.</p>
							</header>
							<ul className="list-unstyled mb-0 rcaTimeList mb-8">
								<li>
									<div className="d-flex align-items-center">
										<div className="icnWrap flex-shrink-0 mr-4">
											<i className="icn icomoon-phone-call text-white"></i>
										</div>
										<div className="decWrap">
											<strong className="d-block fwSemi text-white">Call for book:</strong>
											<strong className="d-block fwSemi text-secondary">+971 50 630 1880</strong>
										</div>
									</div>
								</li>
								<li>
									<div className="d-flex align-items-center">
										<div className="icnWrap flex-shrink-0 mr-4">
											<i className="icn icomoon-clock text-white"></i>
										</div>
										<div className="decWrap">
											<strong className="d-block fwSemi text-white">Working Hours:</strong>
											<strong className="d-block fwSemi text-secondary">09:00-18:00 (Mon-Sat)</strong>
										</div>
									</div>
								</li>
							</ul>
						</div>
						<div className="col-12 col-md-6">
							<div className="formWrap">
								<form action="#" className="requstAcallForm" method="POST" onSubmit={handleSubmit} autoComplete="off">
									<div className="form-row">
										<div className="form-group col-12 mb-2">
											<label className="fLabel fontAlter text-primary d-block">Your Service Request:</label>
											<div className="coolSelectWrapper">
												<select onChange={handleChange} name="service" className="coolSelect form-control" id="service" ref={selectRef} defaultValue="">
													<option value="" disabled="">Select Service</option>
													<option value="Diamond Service">Diamond Service</option>
													<option value="Golden Service">Golden Service</option>
													<option value="Silver Service">Silver Service</option>
													<option value="Normal Service">Normal Service</option>
												</select>
											{errors.service && <span className="error-message text-danger">{errors.service}</span>}
											</div>
										</div>
									</div>
									<div className="form-row">
										<div className="form-group col-12 mb-2">
											<label className="fLabel fontAlter text-primary d-block">Your Name:</label>
											<input onChange={handleChange} type="text" className="form-control w-100" value={formData.name} name="name"/>
											{errors.name && <span className="error-message text-danger">{errors.name}</span>}
										</div>
									</div>
									<div className="form-row">
										<div className="form-group col-12">
											<label className="fLabel fontAlter text-primary d-block">Phone Number:</label>
											<input onChange={handleChange} type="number" className="form-control w-100" value={formData.phone} name="phone"/>
											{errors.phone && <span className="error-message text-danger">{errors.phone}</span>}
										</div>
									</div>
									<div className="form-row">
										<div className="form-group col-12 mb-0">
											<button type="submit" className="btn btnThemeAlt text-capitalize position-relative border-0 p-0 w-100" data-hover="Send Request">
												<span className="d-block btnText">Send Request</span>
											</button>
											{successMessage && <div className="text-success mt-2">{successMessage}</div>}
                    						{errorMessage && <div className="text-danger mt-2">{errorMessage}</div>}
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
				<span className="bgCover bgImg w-100 h-100 position-absolute" style={{ backgroundImage: `url(${require("../assets/images/img13.jpg")})` }}></span>
			</aside>
		</>
	)
}
export {PricingPlan,Workprocess,Contact}