import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const NextArrow = (props) => {
	const { onClick } = props;
  	return (
    <a className="slickNext slickArrowv1 d-flex align-items-center justify-content-center rounded-circle position-absolute" onClick={onClick}>
      <span className="sr-only">Next slide</span>
    </a>
  	);
};

const PrevArrow = (props) => {
  	const { onClick } = props;
  	return (
    <a className="slickPrev slickArrowv1 d-flex align-items-center justify-content-center rounded-circle position-absolute" onClick={onClick}>
      <span className="sr-only">Previous slide</span>
    </a>
  	);
};
const PrevArrow2 = (props) => {
  	const { onClick } = props;
  	return (
    <a className="slickPrev ssArrowVii rounded-circle d-flex align-items-center justify-content-center fas fa-chevron-left position-absolute" onClick={onClick}>
      <span className="sr-only">Previous slide</span>
    </a>
  	);
};
const NextArrow2 = (props) => {
	const { onClick } = props;
  	return (
    <a className="slickNext ssArrowVii rounded-circle d-flex align-items-center justify-content-center fas fa-chevron-right position-absolute" onClick={onClick}>
      <span className="sr-only">Next slide</span>
    </a>
  	);
};
const preventFunc = (event) => {
	event.preventDefault();
}
function IbsSlider(){
	const sliderSettings = {
	    slidesToShow: 1,
		slidesToScroll: 1,
		fade: true,
		arrows: true,
		speed: 1300,
		adaptiveHeight: true,
		autoplaySpeed: 5000,
	    nextArrow: <NextArrow />,
	    prevArrow: <PrevArrow />,
		dots: false,
		autoplay: true,
		responsive: [
			{
				breakpoint: 576,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: false,
					dots: true,
					dotsClass: 'list-unstyled slickDotsv1 d-flex justify-content-center position-absolute mb-0'
				}
			}
		]
  	};
	return (
		<>
		<div className="introBlock ibsSlider" id="home">
			<Slider {...sliderSettings}>
				<div>
					<article className="ibsColumn d-flex w-100 position-relative text-white fontAlter">
						<div className="alignHolder w-100 d-flex align-items-center">
							<div className="align w-100 pt-8 pb-20 pt-md-12 pb-md-29 px-sm-20 px-xlwd-0">
								<div className="container">
									<div className="row">
										<div className="col-12 col-md-9 col-lg-7">
											<h1 className="text-white font-weight-bold h1Large mb-4">
												<strong className="d-block text-uppercase hTitle mb-3 mb-md-5">Care your car</strong>
												<span className="d-block">It’s time to Come Clean your Car</span>
											</h1>
											<p>Professional Car Wash Center to help you to get clean vehicle!</p>
											<button type="button" className="btn btnThemeAlt position-relative border-0 p-0 mt-3 mt-md-6 btnMinMedium" data-hover="Discover More">
												<span className="d-block btnText">Discover More</span>
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
						<span className="bgCover ibsBgWrap w-100 h-100 position-absolute" style={{ backgroundImage: `url(${require("../assets/images/img01.jpg")})` }}></span>
					</article>
				</div>
				<div>
					<article className="ibsColumn d-flex w-100 position-relative text-white fontAlter">
						<div className="alignHolder w-100 d-flex align-items-center">
							<div className="align w-100 pt-8 pb-20 pt-md-12 pb-md-29 px-sm-20 px-xlwd-0">
								<div className="container">
									<div className="row">
										<div className="col-12 col-md-9 col-lg-7">
											<h1 className="text-white font-weight-bold h1Large mb-4">
												<strong className="d-block text-uppercase hTitle mb-3 mb-md-5">Quick And Easy</strong>
												<span className="d-block">Keep your Car Clean Always</span>
											</h1>
											<p>We are dedicated to provide best quality services!</p>
											<button type="button" className="btn btnThemeAlt position-relative border-0 p-0 mt-3 mt-md-6 btnMinMedium" data-hover="Discover More">
												<span className="d-block btnText">Discover More</span>
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
						<span className="bgCover ibsBgWrap w-100 h-100 position-absolute" style={{ backgroundImage: `url(${require("../assets/images/img07.jpg")})` }}></span>
					</article>
				</div>
				<div>
					<article className="ibsColumn d-flex w-100 position-relative text-white fontAlter">
						<div className="alignHolder w-100 d-flex align-items-center">
							<div className="align w-100 pt-8 pb-20 pt-md-12 pb-md-29 px-sm-20 px-xlwd-0">
								<div className="container">
									<div className="row">
										<div className="col-12 col-md-9 col-lg-7">
											<h1 className="text-white font-weight-bold h1Large mb-4">
												<strong className="d-block text-uppercase hTitle mb-3 mb-md-5">Professional Auto Care</strong>
												<span className="d-block">Save your car’s Original Finish</span>
											</h1>
											<p>Making you Nature and Faster Shine Car wash services!</p>
											<button type="button" className="btn btnThemeAlt position-relative border-0 p-0 mt-3 mt-md-6 btnMinMedium" data-hover="Discover More">
												<span className="d-block btnText">Discover More</span>
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
						<span className="bgCover ibsBgWrap w-100 h-100 position-absolute" style={{ backgroundImage: `url(${require("../assets/images/img08.jpg")})` }}></span>
					</article>
				</div>
			</Slider>
		</div>
		<aside className="bannerAsideBlock text-center fontAlter text-white pt-7 pb-7 pt-lg-12 pb-lg-12">
			<div className="container">
				<div className="babColumnsWrap position-relative text-left mb-md-4 mt-n20 mt-lg-n30">
					<div className="row">
						<div className="col-12 col-sm-6 col-xl-3">
							<article className="bg-white rounded babFeatureColumn pr-5 pl-5 pt-6 pb-5 pr-xl-7 pl-xl-8 pt-xl-8 pb-xl-7 mb-6">
								<span className="icnWrap d-flex align-item-center mb-5">
									<i className="icomoon-spray"></i>
								</span>
								<h2 className="mb-0 position-relative pb-3 pb-xl-5">
									<a href="" onClick={preventFunc}>Car wash 100% without detergents</a>
								</h2>
							</article>
						</div>
						<div className="col-12 col-sm-6 col-xl-3">
							<article className="bg-white rounded babFeatureColumn pr-5 pl-5 pt-6 pb-5 pr-xl-7 pl-xl-8 pt-xl-8 pb-xl-7 mb-6">
								<span className="icnWrap d-flex align-item-center mb-5">
									<i className="icomoon-car-wash-3"></i>
								</span>
								<h2 className="mb-0 position-relative pb-3 pb-xl-5">
									<a href="" onClick={preventFunc}>Rain/Snow protection programs</a>
								</h2>
							</article>
						</div>
						<div className="col-12 col-sm-6 col-xl-3">
							<article className="bg-white rounded babFeatureColumn pr-5 pl-5 pt-6 pb-5 pr-xl-7 pl-xl-8 pt-xl-8 pb-xl-7 mb-6">
								<span className="icnWrap d-flex align-item-center mb-5">
									<i className="icomoon-vacuum-cleaner"></i>
								</span>
								<h2 className="mb-0 position-relative pb-3 pb-xl-5">
									<a href="" onClick={preventFunc}>Efficient modern wash machines</a>
								</h2>
							</article>
						</div>
						<div className="col-12 col-sm-6 col-xl-3">
							<article className="bg-white rounded babFeatureColumn pr-5 pl-5 pt-6 pb-5 pr-xl-7 pl-xl-8 pt-xl-8 pb-xl-7 mb-6">
								<span className="icnWrap d-flex align-item-center mb-5">
									<i className="icomoon-car"></i>
								</span>
								<h2 className="mb-0 position-relative pb-3 pb-xl-5">
									<a href="" onClick={preventFunc}>Online Appointment Booking system</a>
								</h2>
							</article>
						</div>
					</div>
				</div>
				<p><strong className="fwMedium">Offering quick and efficient service to keep your car clean <span className="d-block mt-4 mt-lg-0 d-lg-inline"><a href="tel:971506301880" className="btnCalto d-inline-block overflow-hidden position-relative align-middle text-white ml-lg-2">Call for booking: <span className="bctLink">971-50-630-1880</span><span className="bg w-100 h-100 position-absolute"></span></a></span></strong></p>
			</div>
		</aside>
		</>
	)
}
function Services(){
	const sliderSettings1 = {
	    slidesToShow: 3,
		slidesToScroll: 1,
		arrows: false,
		dots: true,
		dotsClass: 'list-unstyled slickDotsv2 w-100 d-flex justify-content-center mb-0 mt-3 mt-md-6',
		autoplay: true,
		responsive: [
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
			},{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
  	};
	return (
		<>
			<section className="servicesBlock bg-light2 pt-7 pb-7 py-md-12 pb-xl-12 pt-lgwd-15 pb-lgwd-14 pb-xl-15 pt-xl-22 pb-xl-21" id="services">
				<div className="container">
					<header className="headingBtnHead mb-6 mb-md-11 text-center text-sm-left">
						<div className="row align-items-center">
							<div className="col-12 col-sm-7 col-xl-6">
								<h2 className="fwSemi mb-5 mb-sm-0">We’re dedicated to providing best quality service</h2>
							</div>
							<div className="col-12 col-sm-5 col-xl-6 text-sm-right">
								<a href="" onClick={preventFunc} className="btn btnThemeAlt position-relative border-0 p-0 btnMinLarge" data-hover="Explore Services">
									<span className="d-block btnText">Explore Services</span>
								</a>
							</div>
						</div>
					</header>
					<div className="row d-block mt-n6">
						<div className="sbSlider mx-auto mx-md-0">
							<Slider {...sliderSettings1}>
								<div>
									<div className="col-12">
										
										<div className="esColumn position-relative rounded d-block mb-6 mt-10">
											<div className="imgHolder position-relative rounded overflow-hidden">
												<img src={require("../assets/images/img03.jpg")} className="img-fluid rounded w-100" alt="description"/>
											</div>
											<div className="esCaption position-absolute px-4 px-xl-8 py-14">
												<span className="icnWrap position-absolute rounded-circle d-flex align-items-center justify-content-center shadow">
													<i className="icomoon-2371544"><span className="sr-only">icon</span></i>
												</span>
												<h3 className="h3Small mb-1"><a href="" onClick={preventFunc}>Express Exterior</a></h3>
												<div className="onHover descriptWrap">
													<p>Keep your car like Showroom Condition</p>
													<ul className="list-unstyled pl-0 mb-0 mt-5 pcFeaturesList pcfAlt">
														<li>Seats Washing</li>
														<li>Vacum Cleaning</li>
														<li>Spot-free Thermal dry</li>
														<li>Rain shield</li>
														<li>Triple Foam</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div>
									<div className="col-12">
										
										<div className="esColumn position-relative rounded d-block mb-6 mt-10">
											<div className="imgHolder position-relative rounded overflow-hidden">
												<img src={require("../assets/images/img04.jpg")} className="img-fluid rounded w-100" alt="description"/>
											</div>
											<div className="esCaption position-absolute px-4 px-xl-8 py-14">
												<span className="icnWrap position-absolute rounded-circle d-flex align-items-center justify-content-center shadow">
													<i className="icomoon-air-blower"><span className="sr-only">icon</span></i>
												</span>
												<h3 className="h3Small mb-1"><a href="" onClick={preventFunc}>Auto Detailing</a></h3>
												<div className="onHover descriptWrap">
													<p>Keep your car like Showroom Condition</p>
													<ul className="list-unstyled pl-0 mb-0 mt-5 pcFeaturesList pcfAlt">
														<li>Seats Washing</li>
														<li>Vacum Cleaning</li>
														<li>Spot-free Thermal dry</li>
														<li>Rain shield</li>
														<li>Triple Foam</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div>
									<div className="col-12">
										
										<div className="esColumn position-relative rounded d-block mb-6 mt-10">
											<div className="imgHolder position-relative rounded overflow-hidden">
												<img src={require("../assets/images/img05.jpg")} className="img-fluid rounded w-100" alt="description"/>
											</div>
											<div className="esCaption position-absolute px-4 px-xl-8 py-14">
												<span className="icnWrap position-absolute rounded-circle d-flex align-items-center justify-content-center shadow">
													<i className="icomoon-car-wash-1"><span className="sr-only">icon</span></i>
												</span>
												<h3 className="h3Small mb-1"><a href="" onClick={preventFunc}>Full Service Wash</a></h3>
												<div className="onHover descriptWrap">
													<p>Keep your car like Showroom Condition</p>
													<ul className="list-unstyled pl-0 mb-0 mt-5 pcFeaturesList pcfAlt">
														<li>Seats Washing</li>
														<li>Vacum Cleaning</li>
														<li>Spot-free Thermal dry</li>
														<li>Rain shield</li>
														<li>Triple Foam</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div>
									<div className="col-12">
										
										<div className="esColumn position-relative rounded d-block mb-6 mt-10">
											<div className="imgHolder position-relative rounded overflow-hidden">
												<img src={require("../assets/images/img04.jpg")} className="img-fluid rounded w-100" alt="description"/>
											</div>
											<div className="esCaption position-absolute px-4 px-xl-8 py-14">
												<span className="icnWrap position-absolute rounded-circle d-flex align-items-center justify-content-center shadow">
													<i className="icomoon-air-blower"><span className="sr-only">icon</span></i>
												</span>
												<h3 className="h3Small mb-1"><a href="" onClick={preventFunc}>Auto Detailing</a></h3>
												<div className="onHover descriptWrap">
													<p>Keep your car like Showroom Condition</p>
													<ul className="list-unstyled pl-0 mb-0 mt-5 pcFeaturesList pcfAlt">
														<li>Seats Washing</li>
														<li>Vacum Cleaning</li>
														<li>Spot-free Thermal dry</li>
														<li>Rain shield</li>
														<li>Triple Foam</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
							</Slider>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}
function Photogallery(){
	const sliderSettings2 = {
	    slidesToShow: 2,
			slidesToScroll: 1,
			arrows: true,
			nextArrow: <NextArrow2 />,
	    prevArrow: <PrevArrow2 />,
			infinite: false,
			autoplay: false,
			responsive: [
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				},
				{
					breakpoint: 576,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						infinite: true,
						arrows: true,
						dots: true,
						dotsClass: 'list-unstyled slickDotsv2 w-100 d-flex justify-content-center mb-0 mt-3 mt-md-6',
					}
				}
			]
  	};
	return(
		<>
			<section className="photoGalleryBlock position-relative py-7 py-md-9 py-xl-16" id="portfolio">
				<div className="container">
					<div className="row align-items-center mb-14 mb-md-18 mb-lg-12 mb-xl-14">
						<div className="col-12 col-md-5 col-lg-4">
							<h2 className="fwSemi mb-sm-5 mb-md-0 text-white">See Gallery to see our best works</h2>
						</div>
						<div className="col-12 col-md-7 col-lg-8">
						</div>
					</div>
					<div className="galleryPhotoSlider mx-n3 mx-sm-n2">
						<Slider {...sliderSettings2}>
							<div className="px-3 px-sm-2">
								<article className="gPhoColumn position-relative overflow-hidden">
									<img src={require("../assets/images/img14.jpg")} className="img-fluid w-100" alt="description"/>
									<h5 className="h5 text-white">
										Complete Detail Wash
										<strong className="pgCategory font-weight-normal text-uppercase d-block">Washing</strong>
									</h5>
									<a href="" onClick={preventFunc} className="d-inline-block pgLink"><i className="rounded-circle icomoon-arrowRight d-flex align-items-center justify-content-center pgLinkGo"><span className="sr-only">icon</span></i></a>
								</article>
							</div>
							<div className="px-3 px-sm-2">
								<article className="gPhoColumn position-relative overflow-hidden">
									<img src={require("../assets/images/img15.jpg")} className="img-fluid w-100" alt="description"/>
									<h5 className="h5 text-white">
										Dashboard Cleaning
										<strong className="pgCategory font-weight-normal text-uppercase d-block">Interior</strong>
									</h5>
									<a href="" onClick={preventFunc} className="d-inline-block pgLink"><i className="rounded-circle icomoon-arrowRight d-flex align-items-center justify-content-center pgLinkGo"><span className="sr-only">icon</span></i></a>
								</article>
							</div>
							<div className="px-3 px-sm-2">
								<article className="gPhoColumn position-relative overflow-hidden">
									<img src={require("../assets/images/img17.png")} className="img-fluid w-100" alt="description"/>
									<h5 className="h5 text-white">
										Machine Washing
										<strong className="pgCategory font-weight-normal text-uppercase d-block">Auto Detail</strong>
									</h5>
									<a href="" onClick={preventFunc} className="d-inline-block pgLink"><i className="rounded-circle icomoon-arrowRight d-flex align-items-center justify-content-center pgLinkGo"><span className="sr-only">icon</span></i></a>
								</article>
							</div>
							<div className="px-3 px-sm-2">
								<article className="gPhoColumn position-relative overflow-hidden">
									<img src={require("../assets/images/img16.png")} className="img-fluid w-100" alt="description"/>
									<h5 className="h5 text-white">
										RMW Mini Cooper
										<strong className="pgCategory font-weight-normal text-uppercase d-block">Washing, Interior</strong>
									</h5>
									<a href="" onClick={preventFunc} className="d-inline-block pgLink"><i className="rounded-circle icomoon-arrowRight d-flex align-items-center justify-content-center pgLinkGo"><span className="sr-only">icon</span></i></a>
								</article>
							</div>
							<div className="px-3 px-sm-2">
								<article className="gPhoColumn position-relative overflow-hidden">
									<img src={require("../assets/images/img14.jpg")} className="img-fluid w-100" alt="description"/>
									<h5 className="h5 text-white">
										Complete Detail Wash
										<strong className="pgCategory font-weight-normal text-uppercase d-block">Washing</strong>
									</h5>
									<a href="" onClick={preventFunc} className="d-inline-block pgLink"><i className="rounded-circle icomoon-arrowRight d-flex align-items-center justify-content-center pgLinkGo"><span className="sr-only">icon</span></i></a>
								</article>
							</div>
							<div className="px-3 px-sm-2">
								<article className="gPhoColumn position-relative overflow-hidden">
									<img src={require("../assets/images/img15.jpg")} className="img-fluid w-100" alt="description"/>
									<h5 className="h5 text-white">
										Dashboard Cleaning
										<strong className="pgCategory font-weight-normal text-uppercase d-block">Interior</strong>
									</h5>
									<a href="" onClick={preventFunc} className="d-inline-block pgLink"><i className="rounded-circle icomoon-arrowRight d-flex align-items-center justify-content-center pgLinkGo"><span className="sr-only">icon</span></i></a>
								</article>
							</div>
							<div className="px-3 px-sm-2">
								<article className="gPhoColumn position-relative overflow-hidden">
									<img src={require("../assets/images/img17.png")} className="img-fluid w-100" alt="description"/>
									<h5 className="h5 text-white">
										Machine Washing
										<strong className="pgCategory font-weight-normal text-uppercase d-block">Auto Detail</strong>
									</h5>
									<a href="" onClick={preventFunc} className="d-inline-block pgLink"><i className="rounded-circle icomoon-arrowRight d-flex align-items-center justify-content-center pgLinkGo"><span className="sr-only">icon</span></i></a>
								</article>
							</div>
							<div className="px-3 px-sm-2">
								<article className="gPhoColumn position-relative overflow-hidden">
									<img src={require("../assets/images/img16.png")} className="img-fluid w-100" alt="description"/>
									<h5 className="h5 text-white">
										RMW Mini Cooper
										<strong className="pgCategory font-weight-normal text-uppercase d-block">Washing, Interior</strong>
									</h5>
									<a href="" onClick={preventFunc} className="d-inline-block pgLink"><i className="rounded-circle icomoon-arrowRight d-flex align-items-center justify-content-center pgLinkGo"><span className="sr-only">icon</span></i></a>
								</article>
							</div>
						</Slider>
					</div>
					<div id="projects-hidden" className="hide"></div>
				</div>
			</section>
		</>
	)
}
function Testimonial(){
	const sliderSettings3 = {
	    slidesToShow: 1,
			slidesToScroll: 1,
			dots: true,
			arrows: false,
			autoplay: true,
			autoplaySpeed: 5000,
			speed: 800,
			dotsClass: 'list-unstyled slickDotsv2 w-100 d-flex justify-content-center mb-0 mt-3 mt-md-6'
  	};
	return (
		<>
		<article className="testimonialsBlock overlay position-relative pt-10 pb-5 pb-md-7 pt-lg-14 pb-lg-12 pt-xl-21 pb-xl-19">
				<div className="container">
					<div className="row">
						<div className="col-12 col-md-10 offset-md-1 col-xl-6 offset-xl-3">
							<header className="headingHead fzMedium mb-8 mb-md-12 text-center px-xl-10">
								<h2>
									<strong className="d-block text-uppercase hTitle fwMedium mb-4">Testimonials</strong>
									<span className="d-block fwSemi">What people think about our services</span>
								</h2>
							</header>
						</div>
					</div>
					<div className="row">
						<div className="col-12 col-md-10 offset-md-1 px-xl-13">
							<div className="quotesSlider">
								<Slider {...sliderSettings3}>
									<div>
										<blockquote className="clientQuote fontCabin position-relative pt-25 pt-sm-0 pl-sm-35 pl-lg-40 pl-lgwd-50 mb-0">
											<q className="d-block position-relative mb-4 mb-md-6 mb-lg-7 pt-4 pl-12 pl-sm-0">I love the efficiency of my Car Wash on Sunrise Valvernasa in Mangho Qor nova. A smiling greeting, an excellent wash, and on my way—clean. The best car wash ever!</q>
											<cite className="d-block pl-12 pl-sm-0">
												<span className="profilePicWrap position-absolute overflow-hidden rounded-circle">
													<img src={require("../assets/images/img09.png")} className="img-fluid w-100 h-100 imgFit rounded-circle" alt="Donald Salvor || Customer - Audi"/>
												</span>
												<span className="eWrap d-block">
													<strong className="d-block fwmMedium text-primary text-capitalize mb-1">Donald Salvor</strong>
													<strong className="d-block subtitle fwmMedium fontBase">Customer - Audi</strong>
												</span>
											</cite>
										</blockquote>
									</div>
									<div>
										<blockquote className="clientQuote fontCabin position-relative pt-25 pt-sm-0 pl-sm-35 pl-lg-40 pl-lgwd-50 mb-0">
											<q className="d-block position-relative mb-4 mb-md-6 mb-lg-7 pt-4 pl-12 pl-sm-0">BNI India is India’s largest and most successful business net working organisation. We offer our members the opportunity to share ideas, contacts, and most importantly!</q>
											<cite className="d-block pl-12 pl-sm-0">
												<span className="profilePicWrap position-absolute overflow-hidden rounded-circle">
													<img src={require("../assets/images/img10.png")} className="img-fluid w-100 h-100 imgFit rounded-circle" alt="Kristina Castle || Medical Scientist"/>
												</span>
												<span className="eWrap d-block">
													<strong className="d-block fwmMedium text-primary text-capitalize mb-1">Dr. Kristina Castle</strong>
													<strong className="d-block subtitle fwmMedium fontBase">Medical Scientist</strong>
												</span>
											</cite>
										</blockquote>
									</div>
									<div>
										<blockquote className="clientQuote fontCabin position-relative pt-25 pt-sm-0 pl-sm-35 pl-lg-40 pl-lgwd-50 mb-0">
											<q className="d-block position-relative mb-4 mb-md-6 mb-lg-7 pt-4 pl-12 pl-sm-0">BNI India is India’s largest and most successful business net working organisation. We offer our members the opportunity to share ideas, contacts, and most importantly!</q>
											<cite className="d-block pl-12 pl-sm-0">
												<span className="profilePicWrap position-absolute overflow-hidden rounded-circle">
													<img src={require("../assets/images/img11.png")} className="img-fluid w-100 h-100 imgFit rounded-circle" alt="Donald Salvor || Global Initiative"/>
												</span>
												<span className="eWrap d-block">
													<strong className="d-block fwmMedium text-primary text-capitalize mb-1">Anthony Wills</strong>
													<strong className="d-block subtitle fwmMedium fontBase">City Mayor-Danya</strong>
												</span>
											</cite>
										</blockquote>
									</div>
								</Slider>
							</div>
						</div>
					</div>
				</div>
				<span className="bgCover imgBg w-100 h-100 position-absolute" style={{ backgroundImage: `url(${require("../assets/images/bgPattern07.jpg")})` }}></span>
			</article>
		</>
	)
}
function Sponsorslogo(){
	const sliderSettings4 = {
    slidesToShow: 5,
		slidesToScroll: 1,
		dots: false,
		arrows: false,
		autoplay: true,
		responsive: [
			{
				breakpoint: 1230,
				settings: {
					slidesToScroll: 1,
					slidesToShow: 4
				}
			},
			{
				breakpoint: 992,
				settings: {
					slidesToScroll: 1,
					slidesToShow: 3
				}
			},
			{
				breakpoint: 576,
				settings: {
					slidesToScroll: 1,
					slidesToShow: 2,
				}
			}
		]
	};
	return (
		<>
			<aside className="sponsorsAside position-relative text-center py-6 py-md-10">
				<div className="container">
					<div className="logosSlider">
						<Slider {...sliderSettings4}>
							<div>
								<div className="logoWrap">
									<img src={require("../assets/images/logoStyles.png")} className="img-fluid" alt="stylus"/>
								</div>
							</div>
							<div>
								<div className="logoWrap">
									<img src={require("../assets/images/logoDrkht.png")} className="img-fluid" alt="drkht"/>
								</div>
							</div>
							<div>
								<div className="logoWrap">
									<img src={require("../assets/images/logoBrook.png")} className="img-fluid" alt="Brook."/>
								</div>
							</div>
							<div>
								<div className="logoWrap">
									<img src={require("../assets/images/logoAlive.png")} className="img-fluid" alt="its alive!"/>
								</div>
							</div>
							<div>
								<div className="logoWrap">
									<img src={require("../assets/images/logoNowhere.png")} className="img-fluid" alt="nowhere among us"/>
								</div>
							</div>
							<div>
								<div className="logoWrap">
									<img src={require("../assets/images/logoStyles.png")} className="img-fluid" alt="stylus"/>
								</div>
							</div>
							<div>
								<div className="logoWrap">
									<img src={require("../assets/images/logoDrkht.png")} className="img-fluid" alt="drkht"/>
								</div>
							</div>
							<div>
								<div className="logoWrap">
									<img src={require("../assets/images/logoBrook.png")} className="img-fluid" alt="Brook."/>
								</div>
							</div>
							<div>
								<div className="logoWrap">
									<img src={require("../assets/images/logoAlive.png")} className="img-fluid" alt="its alive!"/>
								</div>
							</div>
							</Slider>
					</div>
				</div>
			</aside>
		</>
	)
}

export {IbsSlider,Services,Photogallery,Testimonial,Sponsorslogo};