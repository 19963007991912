import React, { useState, useEffect } from 'react';
import { Link as ScrollLink } from 'react-scroll';

const preventFunc = (event) => {
	event.preventDefault();
}
function Footer(){
	const currentYear = new Date().getFullYear();
	return (
		<>
			<div className="ftAreaWrap bg-light">
				<aside className="ftConnectAside pt-4 pb-3 pt-md-7 pb-md-7 text-center text-md-left">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-12 col-lg-7">
								<nav className="ftcaNav mb-4 mb-lg-0">
									<ul className="list-unstyled d-flex flex-wrap mb-0 justify-content-center justify-content-lg-start">
										<li>
											<ScrollLink
                          to="about"
                          spy={true}
                          smooth={true}
                          offset={-70} // Adjust this value to fit your layout
                          duration={500}
                          className=""
                          style={{ cursor: 'pointer' }}
                        >
                          About
                        </ScrollLink>
										</li>
										<li>
											<ScrollLink
                          to="services"
                          spy={true}
                          smooth={true}
                          offset={-70} // Adjust this value to fit your layout
                          duration={500}
                          className=""
                          style={{ cursor: 'pointer' }}
                        >
                          Services
                        </ScrollLink>
										</li>
										<li>
											<ScrollLink
                          to="package"
                          spy={true}
                          smooth={true}
                          offset={-70} // Adjust this value to fit your layout
                          duration={500}
                          className=""
                          style={{ cursor: 'pointer' }}
                        >
                          Packages
                        </ScrollLink>
										</li>
										<li>
											<ScrollLink
                          to="portfolio"
                          spy={true}
                          smooth={true}
                          offset={-70} // Adjust this value to fit your layout
                          duration={500}
                          className=""
                          style={{ cursor: 'pointer' }}
                        >
                          Portfolio
                        </ScrollLink>
										</li>
										<li>
											<ScrollLink
                          to="contact"
                          spy={true}
                          smooth={true}
                          offset={-70} // Adjust this value to fit your layout
                          duration={500}
                          className=""
                          style={{ cursor: 'pointer' }}
                        >
                          Contact
                        </ScrollLink>
										</li>
									</ul>
								</nav>
							</div>
							<div className="col-12 col-lg-5">
								<div className="ctConnectWrap d-sm-flex justify-content-sm-center justify-content-lg-end align-items-sm-center">
									<strong className="title flex-shrink-0 mb-1 font-weight-normal mr-sm-3 d-block">Connect With Us</strong>
									<ul className="list-unstyled socialNetworks ftSocialNetworks d-flex flex-wrap justify-content-center justify-content-sm-end mb-0">
										<li>
											<a href="https://www.facebook.com/magiccarwash.ae/" target="_blank" rel="noopener noreferrer">
												<i className="fab fa-facebook-square"><span className="sr-only">facebook</span></i>
											</a>
										</li>
										<li>
											<a href="/" onClick={preventFunc}>
												<i className="fab fa-twitter"><span className="sr-only">twitter</span></i>
											</a>
										</li>
										<li>
											<a href="/" onClick={preventFunc}>
												<i className="fab fa-instagram"><span className="sr-only">instagram</span></i>
											</a>
										</li>
										<li>
											<a href="/" onClick={preventFunc}>
												<i className="fab fa-youtube"><span className="sr-only">youtube</span></i>
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</aside>
				<div className="footerAsideInnerWrap position-relative">
					<aside className="footerAside pt-10 pb-sm-2 pt-xl-15 pb-lg-10 py-lgwd-14 pb-xl-19">
						<div className="container">
							<div className="row">
								<div className="col-12 col-sm-6 col-lg-3 mb-10 mb-lg-0">
									<h5 className="h5 ftHeading text-white mb-4">Our Address</h5>
									<address className="mb-0 ftPlace">
										Building no 62,<br/> opposite to Almaya Super Market<br/> near Eldorado Restaurant,<br/> Abu Dhabi,<br/> United Arab Emirates
									</address>
								</div>
								<div className="col-12 col-sm-6 col-lg-3 mb-10 mb-lg-0">
									<h5 className="h5 ftHeading text-white mb-4">Opening Hours</h5>
									<ul className="list-unstyled ftpScheduleList mb-0">
										<li>
											<span className="d-block">Monday - Friday</span>
											<span className="d-block">09:00 AM - 06:00 PM</span>
										</li>
										<li>
											<span className="d-block">Saturday</span>
											<span className="d-block">10:00 AM - 05:00 PM</span>
										</li>
									</ul>
								</div>
								<div className="col-12 col-sm-6 col-lg-3 mb-10 mb-lg-0">
									<h5 className="h5 ftHeading text-white mb-4"><i className="icomoon-whatsapp icn"><span className="sr-only">icon</span></i> +971 50 630 1880</h5>
									<p>If you have any question, feel free to contact us</p>
									<a href="mailto:info@magiccarwash.ae">info@magiccarwash.ae</a>
								</div>
								<div className="col-12 col-sm-6 col-lg-3 mb-10 mb-lg-0">
									<h5 className="h5 ftHeading text-white mb-4">Newsletter</h5>
									<p>Join our newsletter for latest Updates</p>
									<form action="#" className="subscritionForm" onClick={preventFunc}>
										<div className="inputWrap">
											<input type="text" className="form-control w-100" placeholder="Your email address"/>
											<button type="submit" className="btnSubmit"><i className="icn fas fa-paper-plane"></i></button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</aside>
					<footer id="pageFooter" className="text-center pt-6 pb-3 pt-md-8 pb-md-5">
						<div className="container">
							<p><a href="https://magiccarwash.ae" rel="noopener noreferrer">Magic Shine Car Wash </a> -  &copy; {currentYear}. <br className="d-md-none"/>All Rights Reserved</p>
						</div>
					</footer>
					<span className="bgCover bgImg w-100 h-100 position-absolute" style={{ backgroundImage: `url(${require("../assets/images/img12.jpg")})` }}></span>
				</div>
			</div>
			<BackToTopButton/>
		</>
	)
}
const BackToTopButton = () => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setShowButton(window.scrollY > 300);
    };

    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = (e) => {
    e.preventDefault();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <a href="/" id="backToTop" className={`rounded-circle text-center ${showButton ? 'active' : ''}`} onClick={scrollToTop}><span className="lnr lnr-chevron-up icn"></span></a>
  );
};

export default Footer;