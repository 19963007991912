import React from 'react';
import { Link as ScrollLink } from 'react-scroll';

function Header() {
  return (
    <>
      <header id="pageHeader" className="bg-white">
          <div className="container-fluid clearfix">
            <div className="logo">
             <ScrollLink
                to="home"
                spy={true}
                smooth={true}
                offset={-100} 
                duration={500}
                className=""
                style={{ cursor: 'pointer' }}
              >
                <img src={require("../assets/images/logo.png")} className="img-fluid" alt="Magic Shine Car Wash" />
              </ScrollLink>
            </div>
            <nav id="pageNav" className="navbar navbar-expand-lg navbar-light justify-content-end justify-content-lg-start position-static">
              <div className="collapse navbar-collapse pageMainNavCollapse" id="pageMainNavCollapse">
                <ul className="navbar-nav mainNavigation fontAlter fwMedium pl-lg-3 pl-xlwd-9 pl-xxl-18">
                  <li className="nav-item">
                    <ScrollLink
                          to="home"
                          spy={true}
                          smooth={true}
                          offset={-100} 
                          duration={500}
                          className="nav-link"
                          style={{ cursor: 'pointer' }}
                        >
                          Home
                        </ScrollLink>
                  </li>
                  <li className="nav-item">
                    <ScrollLink
                          to="about"
                          spy={true}
                          smooth={true}
                          offset={-100} 
                          duration={500}
                          className="nav-link"
                          style={{ cursor: 'pointer' }}
                        >
                          About
                        </ScrollLink>
                  </li>
                  <li className="nav-item">
                    <ScrollLink
                          to="services"
                          spy={true}
                          smooth={true}
                          offset={-70} 
                          duration={500}
                          className="nav-link"
                          style={{ cursor: 'pointer' }}
                        >
                          Services
                        </ScrollLink>
                  </li>
                  <li className="nav-item">
                    <ScrollLink
                          to="package"
                          spy={true}
                          smooth={true}
                          offset={-70} 
                          duration={500}
                          className="nav-link"
                          style={{ cursor: 'pointer' }}
                        >
                          Packages
                        </ScrollLink>
                  </li>
                  <li className="nav-item">
                    <ScrollLink
                          to="portfolio"
                          spy={true}
                          smooth={true}
                          offset={-70} 
                          duration={500}
                          className="nav-link"
                          style={{ cursor: 'pointer' }}
                        >
                          Portfolio
                        </ScrollLink>
                  </li>
                  <li className="nav-item">
                    <ScrollLink
                          to="contact"
                          spy={true}
                          smooth={true}
                          offset={-70} 
                          duration={500}
                          className="nav-link"
                          style={{ cursor: 'pointer' }}
                        >
                          Contact
                        </ScrollLink>
                  </li>
                </ul>
              </div>
              <div className="hdActionsWrap flex-shrink-0 d-flex justify-content-end align-items-center">
                <a href="tel:971506301880" className="btnHdLink fontAlter fwMedium d-none d-xl-inline">
                  <i className="icomoon-whatsapp icn"><span className="sr-only">icon</span></i>
                  +971 50 630 1880
                </a>
                <button className="navbar-toggler pgNaveOpener border-0 ml-3 position-relative" type="button" data-toggle="collapse" data-target="#pageMainNavCollapse" aria-controls="pageMainNavCollapse" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                </button>
                <ScrollLink
                  to="contact"
                  spy={true}
                  smooth={true}
                  offset={-70} 
                  duration={500}
                  className="btn btnThemeAlt border-0 p-0 ml-lg-3 ml-xxl-6 btnHd"
                  data-hover="Book Appointment"
                >
                <span className="d-block btnText">Book Appointment</span>
                </ScrollLink>
              </div>
            </nav>
          </div>
        </header>
    </>
  );
}
export default Header;
